import React from "react";
import "./App.css"
import { useEffect, useState, useRef } from "react";
import "./Board.css";
import {db} from './firebaseInit.js';
import Header from "./Header";
import { onValue, ref, update } from '@firebase/database';
import { useNavigate } from "react-router-dom";

const Online = () => {

    const [openGames, setGames] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        const query = ref(db);
        return onValue(query, (snapshot) => {
        const data = snapshot.val();

        if (snapshot.exists()) {
            parseData(data);
        }
        });
    }, []);

    const parseData = (data) => {
        let tempGames = [];
        Object.keys(data).forEach(key => {
            if(data[key].open && !data[key].started) {
                tempGames.push({"title":key, "data":data[key]});
            }
            console.log(data[key]);
        });
        setGames(tempGames);
    }

    const getGames = openGames.map((game) => 
            <OnlineLink game={game} />
        );
    
    
    return (
    <>
        <Header subtitle="Online"/>
        <div class="content">
            <h3>Open Games:</h3>
            {getGames}
            <button id="create-button" onClick={() => {
                navigate("/create");
            }}>Create Game</button>
        </div>
    </>
    );
}


const OnlineLink = ({game}) => {

    let navigate = useNavigate();

    const updateDatabase = (game_id) => {
        update(ref(db, game_id), {
            started:true,
        });

    }

    return (
        <>
            <div id="online-link">
                <p class="bold">{game.title}</p>
                <p id="link" onClick={() => {
                    updateDatabase(game.title);
                    navigate("/play", {state:{isWhite: false, game_id:game.title, size:JSON.parse(game.data.board).length}});
                    

                }}>Join</p>
            </div>
        </>
    );
}

export default Online;