import React from "react";
import { useEffect, useState, useRef } from "react";
import "./Board.css"
import Stone from "./Stone";
import Header from "./Header";

const LocalNative = () => {

    const [startGame, setStart] = useState(false);
    const [boardSize, setBoardSize] = useState(19);
    let totalWidth = 500;
    let checked = Array.from({length: boardSize},()=>Array(boardSize).fill(null));
    //console.log(checked[18][18]);
    const neighbors = [[0, 1], [0, -1], [1, 0], [-1, 0]];


    const [whitesTurn, setTurn] = useState(false);
    const [stones, setStones] = useState(Array.from({length: boardSize},()=>Array(boardSize).fill(null)));
    const [history, setHistory] = useState(Array.from({length: boardSize},()=>Array(boardSize).fill(null)));

    let currEdge = {"curr":null, "captured":true};
    let checkedScore = Array.from({length: boardSize},()=>Array(boardSize).fill(false));
    let [score, setScore] = useState({"white": 0, "black": 0});

    const [moveNum, setMoveNum] = useState(1);


    useEffect(() => {
        //console.log("new score!");
        setScore(getScore());
    },[stones]);

    const getScore = () => {
        let tempScore = {"white": 0, "black":0};
        // get score of stones
        for(let row = 0; row < boardSize; row++) {
            for(let col = 0; col < boardSize; col++) {
                if(stones[row][col] == "w") {
                    tempScore["white"]++;
                } else if (stones[row][col] == "b") {
                    tempScore["black"]++;
                }
            }
        }

        // get score of captured area
        // captured if only comes into contact with same color
        for(let row = 0; row < boardSize; row++) {
            for(let col = 0; col < boardSize; col++) {
                if(stones[row][col] == null && checkedScore[row][col] == false) {
                    //console.log("looking...");
                    let num = getNumConnected(row,col);
                    if(currEdge["captured"]) {
                        //console.log("area captured");
                        tempScore[currEdge["curr"] == "w" ? "white" : "black"] += num;
                    }
                    currEdge = {"curr":null, "captured":true}
                }
            }
        }

        //console.log(tempScore);

        return tempScore;
    }

    const getNumConnected = (row,col) => {
        checkedScore[row][col] = true;
        let num = 1;
        for(let i = 0; i < neighbors.length; i++) {
            let newR = row + neighbors[i][0];
            let newC = col + neighbors[i][1];

            if(inBounds(newR,newC)) {
                let spot = stones[newR][newC];
                if(spot != null) {
                    if(currEdge["curr"] == null) {
                        currEdge["curr"] = spot;
                    } else if(currEdge["curr"] != spot) {
                        //console.log("nope!");
                        currEdge["captured"] = false;
                    }
                } else if(checkedScore[newR][newC] == false) {
                    num += getNumConnected(newR,newC);
                }
            }
        }
        return num;
    }



    
    const setBoard = (row,col) => {
        if(validMove(row,col)) {
            setHistory(JSON.parse(JSON.stringify(stones)));

            const copy = [...stones];
            copy[row][col] = whitesTurn ? "w" : "b";
            setStones(updateBoard(copy));
            setTurn(!whitesTurn);
            setMoveNum(moveNum + 1);
        } else {
            //console.log("no");
        }
    }

    const validMove = (row,col) => {
        if(stones[row][col]!=null) {
            return false;
        }
        let copyBoard = JSON.parse(JSON.stringify(stones));
        clearBoard(checked);
        //console.log("valid move " + checked[18][18]);
        let newStone = whitesTurn ? "w" : "b";
        copyBoard[row][col] = newStone;
        //console.log("valid move3 " + checked[18][18]);
        copyBoard = updateBoard(copyBoard);
        
        if(isSame(copyBoard, history)) {
            return false;
        }
        //console.log("valid move1 " + checked[18][18]);
        if(getLiberties(row,col,copyBoard) == 0) {
            //console.log("valid move2 " + checked[18][18]);
            //console.log("here");
            return false;
        }

        return true;
        
    }

    function getLiberties(row,col,board) {
        let realTurn = whitesTurn ? "w" : "b";
        let num = 0;
        
        //console.log(checked[row][col]);
        if(checked[row][col] != null) {
            //console.log("nooooo");
            return 0;
        }
        
        checked[row][col] = 1;
        for(let i = 0; i < neighbors.length; i++) {
        let newR = row + neighbors[i][0];
        let newC = col + neighbors[i][1];
        
        if(inBounds(newR, newC)) {
            if(board[newR][newC] == board[row][col]) {
            num += getLiberties(newR,newC,board);
            checked[newR][newC] = 1;
            } else if(board[newR][newC] == null) {
            num++;
            }
        }
        }
        return num;
    }

    function inBounds(row,col) {
        if(row>=0 && col>=0 && row<boardSize && col<boardSize) {
            return true;
        } else {
            return false;
        }
    }

    function clearBoard(board) {
        for(let row = 0; row < boardSize; row++) {
        for(let col = 0; col < boardSize; col++) {
            board[row][col] = null;
        }
        }
    }

    function isSame(board1, board2) {
        for(let row = 0; row < boardSize; row++) {
            for(let col = 0; col < boardSize; col++) {
                if(board1[row][col] != board2[row][col]) {
                    return false;
                }
            }
        }
        return true;
    }

    function updateBoard(board) {
        let copyBoard = JSON.parse(JSON.stringify(board));
        for(let row = 0; row < boardSize; row++) {
            for(let col = 0; col < boardSize; col++) {
                clearBoard(checked);
                
                if(board[row][col] != null && getLiberties(row,col,board) == 0){
                    if(board[row][col] != (whitesTurn ? "w" : "b")) {
                        copyBoard[row][col] = null;
                    }
                }
                clearBoard(checked);
                //console.log("updateboard " + checked[18][18]);
            }
        }
        return copyBoard;
    }











    /* rendering functions */

    const newRow = (row) => {
        let toReturn = [];
        for(let i = 0; i < boardSize; i++) {
            //console.log(boardSize);
            //console.log(stones[row]);
            /*if(row == 18 && i == 18) {
                console.log("row 18");
            }*/
            toReturn.push(<Stone row={row} col={i} color={stones[row][i]} size={totalWidth/boardSize} setBoard={setBoard} whitesTurn={whitesTurn} validMove={validMove}/>);
        }
        return toReturn;
    }


    const fullBoard = [];
    for(let i = 0; i < boardSize; i++) {
        fullBoard.push(
            <div class="flexbox-container">
                {newRow(i)}
            </div>
        );
    }

    const boardStyle = {
        width: totalWidth + "px",
        height: totalWidth + "px",
    }


    const getGrid = () => {

        const gridStyle = {
            width: totalWidth/boardSize + "px",
            height: totalWidth/boardSize + "px",
            boxSizing:"border-box",
            border:"1px solid black"
        }

        const gridRow = () => {
            let tempRow = [];
            for(let col = 0; col < boardSize-1; col++) {
                tempRow.push(<div style={gridStyle}></div>);
            }
            return tempRow;
        }

        let tempGrid = [];

        for(let row = 0; row < boardSize-1; row++) {
            tempGrid.push(
                <div class="grid-row">
                    {gridRow()}
                </div>
            );
        }

        const gridContainerStyle = {
            position:"absolute",
            marginLeft:totalWidth/boardSize/2 + "px",
            marginTop:totalWidth/boardSize/2 + "px",
            boxSizing:"border-box",
            border:"1px solid black"
        }

        return <div style={gridContainerStyle}>{tempGrid}</div>;
    }

    const selectedStyle = {
        backgroundColor:"#0a84ff",
        color:"white"
    }

    return (
    <>

        <Header subtitle="Local Game" twoStep={startGame}/>

        <div class="content">
            {!startGame ?
            <>
            <div id="local-choose-container">
                <button style={boardSize == 9 ? selectedStyle : {}} onClick={() => {
                    setBoardSize(9);
                }}>9x9</button>
                <button style={boardSize == 13 ? selectedStyle : {}} onClick={() => {
                    setBoardSize(13);
                }}>13x13</button>
                <button style={boardSize == 19 ? selectedStyle : {}} onClick={() => {
                    setBoardSize(19);
                }}>19x19</button>
            </div>
                <div id="local-start-container">
                    <button id="local-start" onClick={() => {
                        setStart(true);
                    }}>Start Game</button>
                </div>
            </>
            : 
            <>
            <p class="center" id="turn"><span class="bold">{whitesTurn ? "White" : "Black"}</span>'s turn</p>
            <p class="center" id="score">Black: <span class="bold">{moveNum <= 2 ? 0 : score["black"]}</span>, White: <span class="bold">{score["white"]}</span></p>
            <div style={boardStyle} id="board-container">
                {getGrid()}
                {fullBoard}
            </div>
            <p class="center" id="turn">Move: <span class="bold">{moveNum}</span></p>
            </>
            }
        </div>
    </>
    );
}

export default LocalNative;