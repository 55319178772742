import React from "react";
import { useEffect, useState, useRef } from "react";
import "./App.css"
import { useNavigate } from "react-router-dom";


const Header = ({subtitle, twoStep, onceLeft}) => {

    let navigate = useNavigate();

    const [checkBefore, setCheck] = useState(null);

    
    return (
    <>
        {checkBefore != null ? 
        <>
            <div id="overlay" onClick={()=>{
                setCheck(null);
            }}>
            </div>
            <div id="before-leaving">
                <p>Are you sure you want to leave? All game data will be lost.</p>
                <div id="before-leaving-check">
                    <button onClick={() => {
                        if(onceLeft != null) {
                            onceLeft();
                        }
                        //onsole.log("leavin");
                        navigate(checkBefore);
                        navigate(0);
                        
                    }}>Yes</button>
                    <button onClick={() => {
                        setCheck(null);
                    }}>Cancel</button>
                </div>
            </div> 
        </> : <></>}
        <div class="content">
            <div id="header">
                <h1>Ancient Game of Go</h1>
                <p id="link" onClick={()=>{
                    let path = "/";

                    if(twoStep) {
                        setCheck(path);
                    } else {
                        navigate(path);
                    }
                }}>Home</p>

                <p id="link" onClick={()=>{
                    let path = "/local";

                    if(twoStep) {
                        setCheck(path);
                    } else {
                        navigate(path);
                    }
                }}>2-Player</p>

                <p id="link" onClick={()=>{
                    let path = "/online";

                    if(twoStep) {
                        setCheck(path);
                    } else {
                        navigate(path);
                    }
                }}>Online</p>
            </div>
            <p  id="under-header">{subtitle}</p>
        </div>


    </>
    );
}

export default Header;