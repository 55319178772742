import React from "react";
import "./App.css"
import { useEffect, useState, useRef } from "react";
import "./Board.css";
import Header from "./Header";
import homeMD from "./home.md";
import "./Markdown.css";

const Home = () => {

    const [content, setContent] = useState("");

    useEffect(() => {
        mdParser();
    },[]);

    const mdParser = async () => {
        await fetch(homeMD)
        .then((r) => r.text())
        .then(md  => {
      
          //ul
          md = md.replace(/^\s*\n\*/gm, '<ul>\n*');
          md = md.replace(/^(\*.+)\s*\n([^\*])/gm, '$1\n</ul>\n\n$2');
          md = md.replace(/^\*(.+)/gm, '<li>$1</li>');
          
          //ol
          md = md.replace(/^\s*\n\d\./gm, '<ol>\n1.');
          md = md.replace(/^(\d\..+)\s*\n([^\d\.])/gm, '$1\n</ol>\n\n$2');
          md = md.replace(/^\d\.(.+)/gm, '<li>$1</li>');
          
          //blockquote
          md = md.replace(/^\>(.+)/gm, '<blockquote>$1</blockquote>');
          
          //h
          /*md = md.replace(/[\#]{6}(.+)/g, '<h6 class="md">$1</h6>');
          md = md.replace(/[\#]{5}(.+)/g, '<h5 class="md">$1</h5>');
          md = md.replace(/[\#]{4}(.+)/g, '<h4 class="md">$1</h4>');
          md = md.replace(/[\#]{3}(.+)/g, '<h3 class="md">$1</h3>');*/
          md = md.replace(/[\#]{2}(.+)/g, '<h2 class="md md-h2">$1</h2>');
          md = md.replace(/[\#]{1}(.+)/g, '<h1 class="md md-h1">$1</h1>');
          
          //alt h
          md = md.replace(/^(.+)\n\=+/gm, '<h1>$1</h1>');
          md = md.replace(/^(.+)\n\-+/gm, '<h2>$1</h2>');
          
          //images
          ///\!\[([^\]]+)\]\(([^\)]+)\)/g
          md = md.replace(/!\[([^\]]+)\]\(([^\)]+)\)\s?\{([^\}]+)\}/g, '<img class="md md-image" src="$2" alt="$1" style="width:$3;"/>');
          
          //links
          md = md.replace(/[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g, '<a target="_blank" rel="noopener noreferrer" class="md md-link" href="$2" title="$4">$1</a>');
          
          //font styles
          //md = md.replace(/[\*\_]{2}([^\*\_]+)[\*\_]{2}/g, '<b>$1</b>');
          //md = md.replace(/[\*\_]{1}([^\*\_]+)[\*\_]{1}/g, '<i>$1</i>');
          md = md.replace(/[\~]{2}([^\~]+)[\~]{2}/g, '<del>$1</del>');
          
          //line break
          md = md.replace(/\^\^\^/g, '<br/>');
    
          //pre
          md = md.replace(/^\s*\n\`\`\`(([^\s]+))?/gm, '<pre class="$2">');
          md = md.replace(/^\`\`\`\s*\n/gm, '</pre>\n\n');
          
          //code
          md = md.replace(/[\`]{1}([^\`]+)[\`]{1}/g, '<code>$1</code>');
          
          //p
          md = md.replace(/^\s*(\n)?(.+)/gm, function(m){
            return  /\<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m) ? m : '<p class="md md-p">'+m+'</p>';
          });
          
          //strip p from pre
          md = md.replace(/(\<pre.+\>)\s*\n\<p\>(.+)\<\/p\>/gm, '$1$2');
          setContent(md);
        })  
    
        
      }
    
    return (
    <>
        <Header subtitle="Home"/>
        <div class="left content">
            <div class="md" dangerouslySetInnerHTML={{__html: content}} />
        </div>
    </>
    );
}

export default Home;