import React from "react";
import { useEffect, useState, useRef } from "react";
import "./App.css"
import Header from "./Header";
import { set, ref, onValue, remove } from "firebase/database";
import { db } from "./firebaseInit";
import { useNavigate, useLocation } from "react-router-dom";
import Stone from "./Stone";



const OnlineGame = () => {

    let navigate = useNavigate();
    const location = useLocation();

    let totalWidth = 500;
    let boardSize = location.state.size;

    const [stones, setStones] = useState(Array.from({length: boardSize},()=>Array(boardSize).fill(null)));
    let checked = Array.from({length: boardSize},()=>Array(boardSize).fill(null));
    const neighbors = [[0, 1], [0, -1], [1, 0], [-1, 0]];


    const [whitesTurn, setTurn] = useState(true);
    const [history, setHistory] = useState(Array.from({length: boardSize},()=>Array(boardSize).fill(null)));
    const [prevMove, setPrev] = useState("");

    const [gameOver, setGameOver] = useState(false);


    let currEdge = {"curr":null, "captured":true};
    let checkedScore = Array.from({length: boardSize},()=>Array(boardSize).fill(false));
    let [score, setScore] = useState({"white": 0, "black": 0});

    const [moveNum, setMoveNum] = useState(1);

    useEffect(() => {
        //console.log("new score!");
        setScore(getScore());
    },[stones]);

    const getScore = () => {
        let tempScore = {"white": 0, "black":0};
        // get score of stones
        for(let row = 0; row < boardSize; row++) {
            for(let col = 0; col < boardSize; col++) {
                if(stones[row][col] == "w") {
                    tempScore["white"]++;
                } else if (stones[row][col] == "b") {
                    tempScore["black"]++;
                }
            }
        }

        // get score of captured area
        // captured if only comes into contact with same color
        for(let row = 0; row < boardSize; row++) {
            for(let col = 0; col < boardSize; col++) {
                if(stones[row][col] == null && checkedScore[row][col] == false) {
                    //console.log("looking...");
                    let num = getNumConnected(row,col);
                    if(currEdge["captured"]) {
                        //console.log("area captured");
                        tempScore[currEdge["curr"] == "w" ? "white" : "black"] += num;
                    }
                    currEdge = {"curr":null, "captured":true}
                }
            }
        }

        //console.log(tempScore);

        return tempScore;
    }

    const getNumConnected = (row,col) => {
        checkedScore[row][col] = true;
        let num = 1;
        for(let i = 0; i < neighbors.length; i++) {
            let newR = row + neighbors[i][0];
            let newC = col + neighbors[i][1];

            if(inBounds(newR,newC)) {
                let spot = stones[newR][newC];
                if(spot != null) {
                    if(currEdge["curr"] == null) {
                        currEdge["curr"] = spot;
                    } else if(currEdge["curr"] != spot) {
                        //console.log("nope!");
                        currEdge["captured"] = false;
                    }
                } else if(checkedScore[newR][newC] == false) {
                    num += getNumConnected(newR,newC);
                }
            }
        }
        return num;
    }


    useEffect(() => {
        const query = ref(db, location.state.game_id);
        return onValue(query, (snapshot) => {
        const data = snapshot.val();

        if (snapshot.exists()) {
            //console.log(data);
            setStones(JSON.parse(data.board));
            //console.log(data.recentMove);
            //console.log(location.state.isWhite ? "w" : "b");
            setTurn(!(data.recentMove == "w"));
            setPrev(data.recentMove);
            setMoveNum(data.numMoves);
            console.log("move made");
        } else {
            setGameOver(true);
            //console.log("nothing here");
        }
        });
    }, [location]);



    const updateDatabase = (newBoard) => {
        set(ref(db, location.state.game_id), {
            open:true,
            started:true,
            board: JSON.stringify(newBoard),
            numMoves:moveNum+1,
            recentMove: location.state.isWhite ? "w" : "b"
        });

    }

    const resign = () => {
        remove(ref(db, location.state.game_id));
    }




    const setBoard = (row,col) => {
        if(validMove(row,col)) {
            //setHistory(JSON.parse(JSON.stringify(stones)));

            const copy = [...stones];
            copy[row][col] = whitesTurn ? "w" : "b";
            let temp = updateBoard(copy);
            setStones(temp);
            setTurn(!whitesTurn);
            updateDatabase(temp);
            setHistory(temp);
        } else {
            //console.log("no");
        }
    }

    const validMove = (row,col) => {
        if(gameOver || prevMove == (location.state.isWhite ? "w" : "b") || stones[row][col]!=null) {
            return false;
        }
        let copyBoard = JSON.parse(JSON.stringify(stones));
        clearBoard(checked);
        let newStone = whitesTurn ? "w" : "b";
        copyBoard[row][col] = newStone;
        copyBoard = updateBoard(copyBoard);
        if(isSame(copyBoard, history)) {
            return false;
        }
        if(getLiberties(row,col,copyBoard) == 0) {
            return false;
        }
        
        return true;
        
    }

    function getLiberties(row,col,board) {
        let realTurn = whitesTurn ? "w" : "b";
        let num = 0;
        
        if(checked[row][col] != null) {
        return 0;
        }
        
        checked[row][col] = 1;
        for(let i = 0; i < neighbors.length; i++) {
        let newR = row + neighbors[i][0];
        let newC = col + neighbors[i][1];
        
        if(inBounds(newR, newC)) {
            if(board[newR][newC] == board[row][col]) {
            num += getLiberties(newR,newC,board);
            checked[newR][newC] = 1;
            } else if(board[newR][newC] == null) {
            num++;
            }
        }
        }
        return num;
    }

    function inBounds(row,col) {
        if(row>=0 && col>=0 && row<boardSize && col<boardSize) {
            return true;
        } else {
            return false;
        }
    }

    function clearBoard(board) {
        for(let row = 0; row < boardSize; row++) {
        for(let col = 0; col < boardSize; col++) {
            board[row][col] = null;
        }
        }
    }

    function isSame(board1, board2) {
        for(let row = 0; row < boardSize; row++) {
            for(let col = 0; col < boardSize; col++) {
                if(board1[row][col] != board2[row][col]) {
                    return false;
                }
            }
        }
        return true;
    }

    function updateBoard(board) {
        let copyBoard = JSON.parse(JSON.stringify(board));
        for(let row = 0; row < boardSize; row++) {
            for(let col = 0; col < boardSize; col++) {
                clearBoard(checked);
                if(board[row][col] != null && getLiberties(row,col,board) == 0){
                    if(board[row][col] != (whitesTurn ? "w" : "b")) {
                        copyBoard[row][col] = null;
                    }
                }
                clearBoard(checked);
            }
        }
        return copyBoard;
    }

















    const newRow = (row) => {
        var toReturn = [];
        for(let i = 0; i < boardSize; i++) {
            toReturn.push(<Stone row={row} col={i} color={stones[row][i]} size={totalWidth/boardSize} setBoard={setBoard} whitesTurn={whitesTurn} validMove={validMove}/>);
        }
        return toReturn;
    }


    const fullBoard = [];
    for(let i = 0; i < boardSize; i++) {
        fullBoard.push(
            <div class="flexbox-container">
                {newRow(i)}
            </div>
        );
    }

    const boardStyle = {
        width: totalWidth + "px",
        height: totalWidth + "px",
    }


    const getGrid = () => {

        const gridStyle = {
            width: totalWidth/boardSize + "px",
            height: totalWidth/boardSize + "px",
            boxSizing:"border-box",
            border:"1px solid black"
        }

        const gridRow = () => {
            let tempRow = [];
            for(let col = 0; col < boardSize-1; col++) {
                tempRow.push(<div style={gridStyle}></div>);
            }
            return tempRow;
        }

        let tempGrid = [];

        for(let row = 0; row < boardSize-1; row++) {
            tempGrid.push(
                <div class="grid-row">
                    {gridRow()}
                </div>
            );
        }

        const gridContainerStyle = {
            position:"absolute",
            marginLeft:totalWidth/boardSize/2 + "px",
            marginTop:totalWidth/boardSize/2 + "px",
            boxSizing:"border-box",
            border:"1px solid black"
        }

        return <div style={gridContainerStyle}>{tempGrid}</div>;
    }






    return (
    <>
        <Header subtitle="Online Game" twoStep={true} onceLeft={resign}/>
        <div class="content">
            {location.state.game_id == null ? 
            <p>Error.</p>
            :
            <>
                <p class="center">Game ID: <span class="bold">{location.state.game_id}</span></p>
                <p class="center">You are <span class="bold">{location.state.isWhite ? "White" : "Black"}</span></p>
                <p class="center" id="score">Black: <span class="bold">{moveNum <= 2 ? 0 : score["black"]}</span>, White: <span class="bold">{score["white"]}</span></p>
                <div style={boardStyle} id="board-container">
                    {getGrid()}
                    {fullBoard}
                </div>
                <p class="center" id="your-turn">It is <span class="bold">{prevMove == (location.state.isWhite ? "w" : "b") ? "not your" : "your"}</span> turn</p>
                <p class="center" id="turn">Move: <span class="bold">{moveNum}</span></p>

                {!gameOver ?
                <div id="button-container">
                <button id="create-button" className="center" onClick={() => {
                    resign();
                    navigate("/online");
                }}>Resign</button>
            </div>  
                : <div id="game-over">
                    <p class="center">The other player has resigned.</p>
                    <p id="return-home" onClick={() => {
                        navigate("/online");
                    }}>Return to Online games.</p>
                </div>}
                
            </>
            }
        </div>

    </>
    );
}

export default OnlineGame;