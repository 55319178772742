import React from "react";
import { useEffect, useState, useRef } from "react";
import "./Board.css"

const Stone = ({row, col, color, size, setBoard, whitesTurn, validMove}) => {

    const [possible, setPossible] = useState(false);


    const boxStyle = {
        boxSizing: "border-box",
        display:"flex",
        width: size + "px",
        height: size + "px",
        justifyContent:"center",
        alignItems:"center",
        zIndex:"5"
        
    }

    const getCircle = (bg, placed) => {
        const stoneStyle = {
            backgroundColor: bg,
            width: size + "px",
            height: size + "px",
            borderRadius: "50%",
            opacity: placed ? 1 : .4
        }

        return <div style={stoneStyle}></div>;
    }

    const getContent = () => {
        if(color == null && possible && validMove(row,col)) {
            if(whitesTurn) {
                return getCircle("white", false);
            } else {
                return getCircle("black", false);
            }
        }

        if(color == null) { return <></>; }

        if(color == "w") {
            return getCircle("white", true);
        } else {
            return getCircle("black", true);
        }
    }

    return (
    <>
        <div style={boxStyle} onClick={() => {
            setBoard(row,col);
        }} 
        onMouseEnter={() => {
            setPossible(true);
        }}
        onMouseLeave={() => {
            setPossible(false);
        }}
        >
            {getContent()}
        </div>
    </>
    );
}

export default Stone;