import './App.css';
import LocalGame from './LocalGame';
import LocalNative from './LocalNative';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import Online from './Online';
import CreateGame from './CreateGame';
import OnlineGame from './OnlineGame';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/local" element={<LocalNative/>}/>
            <Route path="/online" element={<Online/>}/>
            <Route path="/create" element={<CreateGame/>}/>
            <Route path="/play" element={<OnlineGame/>}/>
      </Routes>
    </BrowserRouter>
      

    </>
  );
}

export default App;
