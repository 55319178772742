import React from "react";
import { useEffect, useState, useRef } from "react";
import "./App.css"
import Header from "./Header";
import { ref, set, update } from "firebase/database";
import { db } from "./firebaseInit";
import { useNavigate, Link } from "react-router-dom";



const CreateGame = () => {

    let navigate = useNavigate();

    const [gameSettings, setSettings] = useState({"size":19, "name":"", "rand":Math.floor(Math.random() * 9999)});

    const highlightedStyle = {
        backgroundColor:"#0a84ff",
        color:"white"
    }

    const publishGame = () => {
        set(ref(db, gameSettings.name.replaceAll(' ','-') + "-" + gameSettings.rand), {
            open:true,
            started:false,
            board:JSON.stringify(Array.from({length: gameSettings.size},()=>Array(gameSettings.size).fill(null))),
            recentMove:"w",
            numMoves:1,
        });
    }


    return (
    <>
        <Header subtitle="Create Game" twoStep={true}/>
        <div class="content">
            <input placeholder="Name" type="text" id="create-name" defaultValue={gameSettings.name} onChange={(e) => {
                setSettings({...gameSettings, "name":e.target.value});
            }}/>

            <div id="create-size-container">
                <button style={gameSettings.size == 9 ? highlightedStyle : {}} class="create-size-button" onClick={() => {
                    setSettings({...gameSettings, "size":9});
                }}>9x9</button>
                <button style={gameSettings.size == 13 ? highlightedStyle : {}} class="create-size-button" onClick={() => {
                    setSettings({...gameSettings, "size":13});
                }}>13x13</button>
                <button style={gameSettings.size == 19 ? highlightedStyle : {}} class="create-size-button" onClick={() => {
                    setSettings({...gameSettings, "size":19});
                }}>19x19</button>
            </div>


            <button id="create-button" onClick={() => {
                publishGame();
                navigate("/play", {state:{isWhite: true, game_id:gameSettings.name.replaceAll(' ','-') + "-" + gameSettings.rand, size:gameSettings.size}});
            }}>Create Game</button>
        </div>

    </>
    );
}

export default CreateGame;